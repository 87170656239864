import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import flyash from "../assets/ServicesAndProduct/flyash.webp";
import RowSalt from "../assets/ServicesAndProduct/RowSalt.jpg";
import StoneChips from "../assets/ServicesAndProduct/StoneChipes.jpg";
import BlackCoalPowder from "../assets/OurProduct/BlackCoalPowder.webp";
import MarineEquipment from "../assets/OurProduct/MarineEquipment.webp";
import WhiteCoal from "../assets/OurProduct/WhiteCoal.webp";
import WoodCharCoal from "../assets/OurProduct/WoodCharCoal.webp";
import { Container } from "react-bootstrap";

const products = [
  {
    name: "Fly Ash",
    img: flyash,
    desc: "Explore our range of fly ash, including black and red varieties, sourced from cement and RMC plants, ideal for block manufacturing and more.",
  },
  {
    name: "Salt",
    img: RowSalt,
    desc: "Premium Industrial Salt: Explore our product line, featuring high-grade row salt, for various industrial applications.",
  },
  {
    name: "Stone Chips",
    img: StoneChips,
    desc: "Quality Stone Chips: We offer a diverse selection of high-quality stone chips, including aggregates, for various construction needs.",
  },
  {
    name: "Wood-Char Coal",
    img: WoodCharCoal,
    desc: "Discover our range of charcoal products, including Mangrove Wood Charcoal, Hardwood Lump Charcoal, and Black Wood Charcoal, based in Gandhidham, India.",
  },
  {
    name: "White Coal",
    img: WhiteCoal,
    desc: "Industry pioneers offering Fire Wood Chips for boilers and versatile white coal applications, proudly sourced from India.",
  },
  {
    name: "Marine Equipment",
    img: MarineEquipment,
    desc: "Marine Equipment Retailer: Explore our offerings, including Project Heavy Equipment Lashing, for all your maritime needs.",
  },
  {
    name: "Black Coal Powder",
    img: BlackCoalPowder,
    desc: "Quality Black Coal Powder: Choose from our range of high-quality black coal powder for various industrial applications.",
  },
];

const responsive = {
  desktop: { breakpoint: { max: 3000, min: 1054 }, items: 3, slidesToSlide: 3 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 2, slidesToSlide: 2 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1, slidesToSlide: 1 },
};

const cardStyle = {
  borderRadius: "56% 0% 68% 0% / 25% 25% 5% 25%",
  minWidth: "14rem",
  minHeight: "41.5rem",
  width: "20rem",
  margin: "5rem",
  padding: "2px",
  position: "relative",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
};

const imgStyle = {
  borderRadius: "56% 0% 68% 0% / 25% 25% 5% 25%",
  minWidth: "250px",
  minHeight: "330px",
  objectFit: "cover",
};

const cardTextStyle = {
  height: "120px",
  overflow: "auto",
};

const cardButtonStyle = {
  position: "absolute",
  bottom: "20px",
  left: "50%",
  transform: "translateX(-50%)",
};

const cardBodyStyle = {
  position: "relative",
  paddingBottom: "60px",
};

function OurProducts() {
  return (
    <Container
      fluid
      id="ourproducts"
      style={{
        background:
          "linear-gradient(270deg, rgb(189, 195, 199) 0.00%, rgb(29, 187, 232) 100.00%)",
      }}
    >
      <Row className="justify-content-center">
        <Col xs={12} className="text-center">
          <h1
            style={{
              fontFamily: "Open Sans",
              // marginBottom: "1rem",
              marginTop: "4rem",
            }}
          >
            Our Products
          </h1>
          <Carousel
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={3000}
            infinite={true}
            additionalTransfrom={-50}
          >
            {products.map((product, index) => (
              <Card key={index} style={cardStyle}>
                <Card.Img variant="top" src={product.img} style={imgStyle} />
                <Card.Body style={cardBodyStyle}>
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text style={cardTextStyle}>{product.desc}</Card.Text>
                  <a
                    href="#inquiry"
                    className="btn btn-primary"
                    style={cardButtonStyle}
                  >
                    GET QUOTE
                  </a>
                </Card.Body>
              </Card>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default OurProducts;
