import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import SailaniLogo from "../assets/SailaniLogo.png";

export default function FloatingWhatsApps() {
  return (
    <FloatingWhatsApp
      phoneNumber="+919624547866"
      accountName="Sailnai Logistics"
      statusMessage="Typically replies Less then 48hrs"
      allowClickAway="true"
      notificationSound="true"
      avatar={SailaniLogo}
      avatarWidth={10}
      avatarHeight={10}
      chatMessage={["Hello there! 🤝 How can Sailani help You?"]}
      // messageDelay="2"
    />
  );
}
