import React from "react";
import "./styles.css";
import "../FrontPageTextEffect.css";
import Truck from "../assets/BgImgOption2.jpeg";

export default function FrontPage() {
  return (
    <div
      id="home"
      className="container-fluid"
      style={{
        backgroundImage: `url(${Truck})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        opacity: 0.9,
      }}
    >
      <div className="row p-lg-5">
        <div
          className="glitch-wrapper col-lg-6 col-sm-12"
          style={{
            background: "transparent",
          }}
        >
          <h1
            className="glitch m-1 fs-1 mt-5"
            // data-glitch=""
            style={{ color: "black" }}
          >
            Efficient Cargo Transportation for Your Business
          </h1>
          <div className="p-3">
            <p
              className="fs-4"
              style={{ color: "white", background: "transparent" }}
            >
              <span className="text-danger fs-1">S</span>ailani Logistics is a
              trusted transportation company that offers reliable cargo services
              at affordable rates. We provide efficient handling of various
              cargo types, including fly ash, pond ash, sawdust, and more.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
