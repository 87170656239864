import Home from "./screens";

import "./App.css";
import "./components/navBar";

function App() {
  return <Home />;
}

export default App;
