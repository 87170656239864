import AboutUsBgImg from "../assets/About-Us-BG.jpeg";

function ContactUs() {
  return (
    <div className="container-fluid" style={{ color: "white" }} id="inquiry">
      <div
        className="row p-3"
        style={{
          backgroundImage: `url(${AboutUsBgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      >
        <div className="col text-center m-2">
          <h2>GET YOUR PERSONALIZED QUOTES.</h2>
          <div className="formContainer col-md-12">
            <form action="https://api.web3forms.com/submit" method="POST">
              <div className="row">
                <input
                  name="access_key"
                  type="hidden"
                  value="d9632503-8fca-49df-8b67-b3f39d2f26a4"
                />
                <div className="col-sm-6 mt-2 mb-2">
                  <input
                    required
                    aria-label="Name"
                    className="form-control"
                    name="name"
                    placeholder="Name"
                    type="text"
                  />
                </div>
                <div className="col-sm-6 mt-2 mb-2">
                  <input
                    required
                    className="form-control "
                    id="exampleFormControlInput1"
                    name="email"
                    placeholder="Email"
                    type="email"
                  />
                </div>
                <div className="col-sm-6 col-md-6 mt-2 mb-3 ">
                  <input
                    required
                    aria-label="number"
                    className="form-control"
                    name="number"
                    placeholder="Mobile No"
                    type="number"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <textarea
                    required
                    className="form-control"
                    name="Query"
                    placeholder="Message"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <input
                name="redirect"
                type="hidden"
                value="https://sailani-website.web.app/"
              />
              <div className="p-4 ml-3 ">
                <button
                  className="btn btn-outline-secondary rounded-pill border border-2 me-4 text-white"
                  type="reset"
                >
                  Reset
                </button>
                <button
                  className="btn btn-outline-secondary rounded-pill border border-2 text-white"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-sm-6 col-sm-4 p-sm-4" style={{ color: "white" }}>
          <h1 className="text-center">
            <p className="underline">What We Offer</p>
          </h1>
          <p className="fs-5" style={{ textAlign: "justify" }}>
            Welcome to Sailani Logistics, your one-stop solution for
            stevedoring, bulk cargo handling, men power supply, and
            transportation services. We specialize in the handling and
            transportation of fly ash, pond ash, saw dust, and other products.
            With years of experience under our belt, we are dedicated to
            providing efficient and reliable logistics support to our clients.
            Contact us today and let us help you take your business to new
            heights.
          </p>
          <div className="mt-2">
            <div className="d-flex" style={{ color: "white" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                height="20px"
                width="20px"
              >
                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
              </svg>
              <h6 className="ms-1">Gandhidham, Gujarat, India</h6>
            </div>
            <a
              href="mailto:Sailanilogistic@gmail.com"
              style={{ display: "flex", textDecoration: "none" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                height="20px"
                width="20px"
                margin="0 60px"
              >
                <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
              </svg>
              <h6
                className=""
                style={{
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                sailanilogistic@gmail.com
              </h6>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
