export const services = [
  "Man-Power Supply",
  "Project Cargo Handling",
  "Bulk Cargo Handling",
  "Transport",
  "Stevedoring",
];

export const ServiceText = [
  "Deliver flexible and efficient workforce solutions with skilled and dedicated professionals to elevate your business. Your trusted partner for success.",
  "Our expert team excels in precision and care, efficiently managing project cargo with top-tier equipment and expertise.",
  "We excel in bulk cargo services, leveraging modern equipment and expertise for safe, efficient handling of your materials.",
  "Our experts provide top-tier lashing services for project cargo and containers in Gujarat. Ensuring safe and secure transport for your valuable cargo.",
  "We provide experienced professionals equipped with the latest machinery to handle your cargo with utmost safety and efficiency.",
];
