import React from "react";
import NavbarComp from "../components/navBar";
import FrontPage from "../components/frontPage";
import OurServices from "../components/OurServices";
import OurProducts from "../components/OurProducts";
import Footer from "../components/footer";
import ContactUs from "../components/inquery";
import MiniAboutUs from "../components/miniAboutUs";
import FloatingWhatsApp from "../components/FloatingWhatsApp";

function Home() {
  return (
    <>
      <NavbarComp />
      <FrontPage />
      <MiniAboutUs />
      <OurProducts />
      <OurServices />
      <ContactUs />
      <Footer />
      <FloatingWhatsApp />
    </>
  );
}

export default Home;
