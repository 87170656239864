import React from "react";
import SailaniLogo from "../assets/SailaniLogo.png";

export default function Footer() {
  return (
    <div className="pt-5 footer" style={{ backgroundColor: "#005b96" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 text-center">
            <img
              className="company-logo img-fluid"
              src={SailaniLogo}
              alt="Company Logo"
            />
            <p className="text-white fw-bold mt-3">
              Your Cargo, Our Commitment: Sailani Logistics at Your Service
            </p>
            <p className="text-white">
              <i className="fa fa-phone"></i> Phone: +91 9624547866
            </p>
            <p className="text-white">
              <i className="fa fa-envelope-o"></i> Email:
              sailanilogistic@gmail.com
            </p>
          </div>

          <div className="col-lg-4 col-md-6 offset-lg-4">
            <div className="text-center">
              <h3 className="text-orange">Location</h3>
              <p className="text-white">
                Sailani Logistic - office no 32 ground floor manali tower sector
                8 opp bom gim courier Gandhidham (kutch) 370201
              </p>
            </div>
            <div>
              {/* <iframe src={mapSrc} width="100%" height="120"></iframe> */}
              <iframe
                title="google"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d917.7415139800268!2d70.13243!3d23.061706!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950b9ed6dbc7c1d%3A0x3b100df38a1cff11!2sManali%20Tower%20Complex!5e0!3m2!1sen!2sus!4v1704363951159!5m2!1sen!2sus"
                width="100%"
                height="120"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="row mt-4 text-center">
          <div className="col">
            <p className="text-white">
              <small>© 2023. All Rights Reserved.</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
