function MiniAboutUs() {
  return (
    <div
      id="aboutUs"
      className="container-fluid"
      style={{
        background: "#00acdf",
      }}
    >
      <div className="row">
        <div className="col-lg-6 col-sm-12 p-0 m-0">
          <picture>
            <source
              media="(min-width: 700px)"
              srcSet="https://www.ship-technology.com/wp-content/uploads/sites/8/2022/02/GettyImages-968819844-scaled.jpg"
            />
            <img
              srcSet="https://themes.pixelwars.org/logistica/demo-01/wp-content/uploads/sites/2/2023/08/truck-road-d-rendering.jpg"
              alt="Scaler Topics"
              className="img-fluid"
            />
          </picture>
        </div>
        <div className="col-lg-6 col-sm-12 d-flex align-items-center text-center mr-4">
          <div>
            <h1
              className="display-7 text-white mt-4"
              style={{ fontFamily: "Open Sans" }}
            >
              <p>About </p>
            </h1>
            <p className="fs-5 link-light mb-5">
              At Sailani Logistics, we take pride in offering top-notch services
              to our clients. With our expertise in stevedoring and cargo
              handling, we provide reliable and efficient transportation
              solutions for fly ash, pond ash, saw dust and other products. Our
              team of experts ensure that your cargo is handled with utmost care
              and attention right from loading to unloading and transportation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MiniAboutUs;
