import React from "react";
import Card from "react-bootstrap/Card";
import ManPowerSupply from "../assets/OurServices/LabourSupplySailani.jpeg";
import StevedoringSailani from "../assets/OurServices/StevedoringSailani.jpeg";
import BulkCargoSailani from "../assets/OurServices/BulkCargoHandlingsailani2.jpeg";
import CargoHandlingSailani from "../assets/OurServices/ProjectCargoHandlingSailani.jpeg";
import Transport from "../assets/OurServices/Transport.jpg";
import { services, ServiceText } from "../constants";

const ServiceImage = [
  ManPowerSupply,
  CargoHandlingSailani,
  BulkCargoSailani,
  Transport,
  StevedoringSailani,
];

function OurServices() {
  return (
    <div
      className="container-fluid"
      id="ourServices"
      style={{
        background:
          "linear-gradient(270deg, rgb(189, 195, 199) 0.00%, rgb(29, 187, 232) 100.00%)",
      }}
    >
      <div className="row d-flex justify-content-center align-items-center">
        <h1 className="text-center m-5" style={{ fontFamily: "open sans" }}>
          Our Services
        </h1>

        {services.map((service, index) => (
          <div className="col-lg-4 col-sm-12 col-md-6 mb-5 d-flex justify-content-center">
            <Card
              className="text-center"
              style={{
                minHeight: "27rem",
                maxWidth: "21rem",
                maxHeight: "27rem",
                minWidth: "21rem",
              }}
            >
              <Card.Img
                variant="top"
                src={ServiceImage[index]}
                className="img-top"
                style={{
                  minHeight: "250px",
                  maxHeight: "200px",
                }}
              />
              <Card.Body>
                <Card.Title>
                  <p>{service}</p>
                </Card.Title>
                <Card.Text className="fs-6">{ServiceText[index]}</Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OurServices;
