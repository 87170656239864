import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import SailaniLogo from "../assets/SailaniLogo-removebg.png";

export default function NavBar() {
  const [open, setOpen] = useState(false);
  const toggleButton = () => setOpen((i) => !i);

  return (
    <Navbar
      bg="body-tertiary"
      expand="md"
      expanded={open}
      className="navbar sticky-top navbar-light bg-light"
    >
      <Container>
        <Navbar.Brand
          href="#"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={SailaniLogo}
            alt="Sailani Logo"
            style={{ width: "100px", height: "auto" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={toggleButton}
          aria-controls="navbarSupportedContent"
        />
        <Navbar.Collapse onClick={toggleButton} id="navbarSupportedContent">
          <Nav className="me-auto">
            <Nav.Link className="fw-bold dark" href="#home">
              Home
            </Nav.Link>
            <Nav.Link className="fw-bold dark" href="#aboutUs">
              About Us
            </Nav.Link>
            <Nav.Link className="fw-bold dark" href="#ourproducts">
              Our Products
            </Nav.Link>
            <Nav.Link className="fw-bold dark" href="#ourServices">
              Our Services
            </Nav.Link>
            <Nav.Link className="fw-bold dark" href="#inquiry">
              Inquiry
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
